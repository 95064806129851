#blueBg {
  background-color: var(--dusk-color);
  padding-top: 20vh;
  padding-bottom: 20vh;
}

#PWHeader {
  color: var(--white-color);
  font-weight: 300;
  text-align: center;
}

#typedText {
  color: var(--white-color);
  font-weight: 300;
  text-align: center;
  margin-top: 70px;
}

.typed-cursor {
  opacity: 1;
  color: var(--white-color);
}

.typedText-Group {
  color: var(--white-color);
  font-weight: 300;
  text-align: center;
  margin-top: 70px;
  font-size: 0;
}

.blinking {
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Schüler/Lehrer Buttons */
#buttons {
  margin-top: 8%;
}

.introButtons {
  font-size: 1.4rem;
  display: block;
  margin: 0 auto;
  padding-left: 1%;
  padding-right: 1%;

  /* width: 50%; */
  border-radius: 50px;
  cursor: pointer;
}

#buttonSchueler {
  color: var(--white-color);
  padding: 10px;
  margin-right: 2%;
  border: none;
  height: 100%;
}

#buttonLehrer {
  color: var(--white-color);
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid var(--white-color);
  padding: 10px;
  margin-left: 2%;
}

#buttonLehrer:hover {
  color: #293442 !important;
  background-color: var(--white-color) !important;
  padding: 10px !important;
}

#buttonSchueler:hover {
  color: var(--white-color) !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 3px solid var(--primary-color);
  padding: 7px !important;
}

#einleitung {
  width: 60%;
  margin: auto;
  background-color: var(--white-black-color);
  z-index: 2;
  position: relative;
  top: -90px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 7px;
  padding-bottom: 20px;
  min-width: 517.5px;
}

.einleitungText {
  text-decoration: none;
  text-align: center;
  padding: 20px;
  width: 80%;
  margin: auto;
  line-height: 30px;
  font-size: 1.2rem;
}

#einleitungTitle {
  padding-top: 35px;
  font-size: 2rem;
  font-weight: normal;
}

#wasProjektwoche {
  display: block;
}

#vorteileRow {
  margin: 20px;
}

#vorteileRow .tile {
  padding-bottom: 0;
  height: 100%;
}

.vorteilImg {
  padding: 5%;
}

.vorteilImg > img {
  max-height: 200px;
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.vorteilTitel {
  font-size: 1.6rem;
  text-decoration: none;
  text-align: center;
  width: calc(100% + 40px);
  margin: 0;
  margin-left: -20px;
  font-weight: normal;
}

.vorteilText {
  text-decoration: none;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin: auto;
}

.erklärimg > img {
  max-width: 100%;
  max-height: 17vw;
  display: block;
  margin: auto;
}

#werSind {
  margin: 4% 5%;
  text-align: center;
}

.werSindTile {
  margin: 30px;
}

.werSindTitle {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 1.5rem;
}

@media (max-width: 574.99px) {
  #typedText {
    color: var(--white-color);
    font-weight: 300;
    text-align: center;
    margin-top: 30px;
  }

  .typed-cursor {
    color: var(--white-color);
    font-weight: 300;
    text-align: center;
    margin-top: 30px;
    font-size: 1.25rem;
  }

  #buttons {
    margin-top: 18%;
  }

  #buttonSchueler {
    width: 76%;
    margin-left: 14%;
    margin-right: 14%;
  }

  #buttonLehrer {
    border: 2px solid var(--white-color);
    padding: 8px;
    width: 76%;
    margin-left: 14%;
    margin-right: 14%;
    margin-top: 5%;
  }

  #einleitung {
    width: 90%;
    min-width: 0;
  }

  .einleitungText {
    font-size: 1rem;
    width: 85%;
  }

  #einleitungTitle {
    line-height: 1.2;
    width: 90%;
    padding-bottom: 0;
  }

  #wasProjektwoche {
    padding-top: 0;
  }

  #vorteileRow {
    margin: 2px;
  }

  .vorteilText {
    text-decoration: none;
    text-align: center;
    padding: 10px;
    width: 80%;
    margin: auto;
  }
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* Übernommen von index.css */
.sofunkTile {
  margin-left: 3%;
  margin-right: 3%;
}

.sofunkTile .row div:first-child {
  z-index: 1000;
}

.erklär-nummer {
  font-size: 18.75rem;
  text-decoration: none;
  text-align: center;
  width: 80%;
  margin: auto;
  margin-left: 10%;
  font-family: "adelle", serif;
  font-weight: normal;
}

.erklär-titel {
  font-size: 2rem;
  text-decoration: none;
  text-align: left;
  padding: 20px;
  width: 80%;
  margin: 0;
  margin-top: 6%;
  color: var(--primary-color);
  font-family: "adelle", serif;
  font-weight: normal;
}

.erklär-text {
  overflow: hidden;
  padding-left: 20px;
}

#abschlusswort {
  text-decoration: none;
  text-align: center;
  margin: 10%;
  font-family: "adelle", serif;
  font-weight: normal;
  margin-top: 15%;
}

#button-kontakt {
  color: var(--dark-gray-color);
  background: none;
  border: 3px solid var(--dark-gray-color);
  padding: 7px;
  padding-left: 1%;
  padding-right: 1%;
  display: block;
  margin: 0 auto;
  float: left;
  width: 100%;
  margin-left: 4%;
}

/* schule-formular kompiliertes scss */
#schule-formular {
  text-align: center;
  width: 96%;
  max-width: 820px;
  margin: 0 auto 40px auto;
  padding: 30px;
}

#schule-formular h3 {
  font-size: 1.6rem;
  margin-bottom: 30px;
  font-family: "adelle", serif;
}

#schule-formular .sf-logoText {
  display: flex;
  justify-content: center;
  align-items: center;
}

#schule-formular .sf-logoText img {
  height: 2.5rem;
  width: auto;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 7px;
}

#schule-formular .sf-logoText p {
  font-size: 2.5rem;
  cursor: pointer;
}

#schule-formular .sf-link {
  margin: 50px auto;
}

#schule-formular .sf-link p {
  margin-bottom: 0;
}

#schule-formular .sf-link a {
  text-decoration: none;
  font-size: 1.4rem;
}

#schule-formular .sf-link a:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  #button-kontakt:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    padding: 7px !important;
  }
}

@media (max-width: 575.99px) {
  #button-kontakt {
    margin-bottom: 5%;
  }
}
